import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Carousel from "nuka-carousel"
import styles from "./reviews.module.scss"
import leftIcon from "../../static/assets/left.svg"
import rightIcon from "../../static/assets/right.svg"
import Img from "gatsby-image"

function selectKind(kind) {
  if (kind === "solo") {
    return "Solo Traveler"
  } else if (kind === "couple") {
    return "Couple"
  } else if (kind === "family") {
    return "Family"
  } else {
    return null
  }
}

let slideToShowNumber = 3
if (typeof window !== `undefined`) {
  if (window.innerWidth < 768) slideToShowNumber = 1
}

export default () => (
  <StaticQuery
    query={graphql`
      query reviewQuery {
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "reviews" } } }
          sort: { fields: frontmatter___date, order: DESC }
          limit: 6
        ) {
          totalCount
          edges {
            node {
              id
              html
              excerpt(pruneLength: 170)
              fields {
                slug
                contentType
              }
              frontmatter {
                quote
                author
                date(formatString: "MMMM YYYY")
                kind
                path
                photo {
                  childImageSharp {
                    fixed(width: 150, height: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={`${styles.block} ${styles.short}`}>
        <h2 className={styles.title}>Reviews</h2>
        <Carousel
          slidesToShow={slideToShowNumber}
          withoutControls={false}
          renderCenterLeftControls={({ previousSlide }) => (
            <button className="btn btn-lg btn-light" onClick={previousSlide}>
              <img src={leftIcon} alt="left" width="10px" height="10px" />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button className="btn btn-lg btn-light" onClick={nextSlide}>
              <img src={rightIcon} alt="right" width="10px" height="10px" />
            </button>
          )}
        >
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id} className={`${styles.item} ${styles.shortitem}`}>
              <div className={styles.image}>
                <Img fixed={node.frontmatter.photo.childImageSharp.fixed} alt={node.frontmatter.photo.alt} />              
              </div>
              <div className={styles.author}>
                <p>{selectKind(node.frontmatter.kind)}</p>
                <h3>{node.frontmatter.author}</h3>
              </div>
              <div
                className={styles.quote}
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              ></div>
              <p className={styles.cta}>
                <Link to={"/reviews/#" + node.id}>Read More</Link>
              </p>
              <p className={styles.date}>{node.frontmatter.date}</p>
            </div>
          ))}
        </Carousel>
      </div>
    )}
  />
)
