import React from "react"
import Carousel from "react-bootstrap/Carousel"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query sliderQuery {
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "slides" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
                contentType
              }
              frontmatter {
                description
                title
                path
                photo {
                  childImageSharp {
                    fluid(maxWidth: 1800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hp-carousel">
        <Carousel>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Carousel.Item key={node.id}>
              {node.frontmatter.photo &&
                node.frontmatter.photo.childImageSharp && (
                  <Img
                    className="d-block w-100 h-80"
                    fluid={node.frontmatter.photo.childImageSharp.fluid}
                    alt=""
                  />
                )}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    )}
  />
)
