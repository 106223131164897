import React from "react"
import Header from "../components/header"
import Slider from "../components/slider"
import Bio from "../components/bio"
import Rooms from "../components/rooms"
import Services from "../components/services"
import Socials from "../components/socials"
import Offers from "../components/offers"
import Reviews from "../components/reviews"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import Address from "../components/address"
import Container from "react-bootstrap/Container"

export default () => (
  <div>
    <SEO />
    <Header location="home" />
    <Slider position="Home" />
    <Container fluid className="hp-container">
      <Bio />
      <Rooms />
      <Services limit={2} />
      <Offers />
      <Reviews />
      <Socials />
      <Address />
    </Container>
    <Footer extraSpace={true} />
  </div>
)
