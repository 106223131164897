import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Carousel from "nuka-carousel"
import styles from "./offers.module.scss"
import leftIcon from "../../static/assets/left.svg"
import rightIcon from "../../static/assets/right.svg"
import BackgroundImage from "gatsby-background-image"

export default () => (
  <StaticQuery
    query={graphql`
      query offersQuery {
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "offers" } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          totalCount
          edges {
            node {
              id
              html
              fields {
                slug
                contentType
              }
              frontmatter {
                title
                date(formatString: "MMMM YYYY")
                validityStart(formatString: "MMMM YYYY")
                validityEnd(formatString: "MMMM YYYY")
              }
            }
          }
        }
        desktop: file(relativePath: { eq: "offers-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const bgImage = data.desktop.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          fluid={bgImage}
          backgroundColor={`#040e18`}
        >
          <div className={styles.block}>
            <Carousel
              slidesToShow={1}
              withoutControls={false}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  className="btn btn-lg btn-light"
                  onClick={previousSlide}
                >
                  <img src={leftIcon} alt="left" width="10px" height="10px" />
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button className="btn btn-lg btn-light" onClick={nextSlide}>
                  <img src={rightIcon} alt="right" width="10px" height="10px" />
                </button>
              )}
            >
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <div key={node.id} className={styles.item}>
                  <p className={styles.date}>
                    {node.frontmatter.validityStart} -{" "}
                    {node.frontmatter.validityEnd}
                  </p>
                  <div className={styles.description}>
                    <h3>{node.frontmatter.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
                  </div>
                  <p className={styles.cta}>
                    <Link to={"/booking/"} className="btn btn-primary">
                      Book Now
                    </Link>
                  </p>
                </div>
              ))}
            </Carousel>
          </div>
        </BackgroundImage>
      )
    }}
  />
)
