import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import styles from "./bio.module.scss"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query bioQuery {
        markdownRemark(fields: {slug: {eq: "/pages/homepage/"}}) {
          html
          frontmatter {
            title
            description
            photo {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.block}>
        <Row noGutters>          
          <Col sm={12} md={6} lg={6}>
            <div className={styles.description}>
              <h1>{data.markdownRemark.frontmatter.title}</h1>              
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
            </div>
          </Col>
          <Col sm={12} md={6} lg={5}>
            <div className={styles.box}>
              <Img
                className={styles.image}
                fluid={data.markdownRemark.frontmatter.photo.childImageSharp.fluid} 
                alt="Room Image" 
                objectFit="cover"
                objectPosition="50% 50%" 
              /> 
            </div>
          </Col>
        </Row>
      </div>
    )}
  />
)