import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./address.module.scss"
import Newsletter from "./newsletter"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query addressQuery {
        site {
          siteMetadata {
            address {
              lat
              lon
              street
              directions
              url
            }
          }
        }
        desktop: file(relativePath: { eq: "static-map.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      
      const map = data.desktop.childImageSharp.fluid

      return (
      <div className={styles.block}>
        <Row noGutters>
          <Col md={4} sm={12}>
            <a href={data.site.siteMetadata.address.url}>
              <Img fluid={map} alt="map" className={styles.map} />
            </a>
          </Col>
          <Col md={8} sm={12}>
            <div className={styles.description}>
              <h2>Address</h2>
              <p>{data.site.siteMetadata.address.street}</p>
              <h2>Directions</h2>
              <p>{data.site.siteMetadata.address.directions}</p>
            </div>
          </Col>
        </Row>
        <Newsletter />
      </div>
      )}}
  />
)
