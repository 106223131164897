import React, {useState} from "react"
import { navigate } from "gatsby"
import Form from "react-bootstrap/Form"
import styles from "./newsletter.module.scss"
import Col from "react-bootstrap/Col"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default props => {

  let [email, setEmail] = useState()

  // 1. via `.then`
  const handleSubmit = event => {
    event.preventDefault()
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
        navigate("/thanks")
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  return (
    <div className={styles.container} id='newsletter'>
      <h2 className={styles.title}>Subscribe to our Editorial</h2>
      <p className={styles.description}>Join our community of conscious travellers and get first hand details of our fabulous offers and editorial from our Kalukanda Living pages</p>
      <Form
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="newsletter" />
        <input type="hidden" name="bot-field" />
        <Form.Row>
          <Col>
            <Form.Group controlId="formGroupEmail">
              <Form.Label srOnly={true}>Email address</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="email"
                label="Email address"
                placeholder="Add your email"
                className={styles.input}
                onChange={(e)=>setEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Control
              size="lg"
              type="submit"
              value="Signup"
              className="btn btn-primary"
            />
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}
