import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./services.module.scss"

export default (props) => (
  <StaticQuery
    query={
      graphql`
        query servicesQuery {
          allMarkdownRemark(filter: {fields: {contentType: {eq: "services"}}}, limit: 2) {
            totalCount
            edges {
              node {
                id
                html
                excerpt(pruneLength: 400)
                fields {
                  slug
                  contentType
                }
                frontmatter {
                  description
                  title
                  path                  
                  photo {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    }
    render={data => (
      <div className={styles.block}>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <Row key={node.id} noGutters>
            <Col sm={12} md={(index % 2 === 1) ? {span:6, order: 2} : {span:6, order: 1}}>                  
              {node.frontmatter.photo && node.frontmatter.photo.childImageSharp && (
                <div className={(index % 2 === 1) ? "" : styles.box}>
                  <Img
                    className={(index % 2 === 1) ? styles.imageright : styles.imageleft}
                    fluid={node.frontmatter.photo.childImageSharp.fluid} 
                    alt="Room Image" 
                    objectFit="cover"
                    objectPosition="50% 50%" 
                  />            
                </div>
              )}           
            </Col>
            <Col sm={12} md={(index % 2 === 1) ? {span:6, order: 1} : {span:6, order: 2}}>
                <div className={(index % 2 === 1) ? styles.descriptionleft : styles.description}>
                  <h2>{node.frontmatter.title}</h2>                  
                  <div                    
                    dangerouslySetInnerHTML={{ __html: node.excerpt }}
                  />
                  <Link to={node.frontmatter.path} className="btn btn-primary mt-2">Read More</Link>
                </div>
            </Col>
          </Row>
        ))}
      </div>                    
    )}
  />
)