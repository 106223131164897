import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "nuka-carousel"
import styles from "./rooms.module.scss"
import leftIcon from "../../static/assets/left.svg"
import rightIcon from "../../static/assets/right.svg"


let slideToShowNumber = 3
if (typeof window !== `undefined`) { 
  if (window.innerWidth < 768 )
    slideToShowNumber = 1
}

export default () => (
  <StaticQuery
    query={graphql`
      query RoomsQuery {
        markdownRemark(frontmatter: { path: { eq: "/rooms-content" } }) {
          html
          frontmatter {
            title                    
          }
        }
        allMarkdownRemark(
          filter: { fields: { contentType: { eq: "rooms" } } }
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
                contentType
              }
              frontmatter {
                description
                title
                path
                photo {
                  childImageSharp {
                    fluid(maxWidth: 480) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Row noGutters className={styles.block}>
        <Col>
          <h1 className={styles.title}>Rooms</h1>
          <Carousel
            slidesToShow={slideToShowNumber}
            withoutControls={false}
            cellSpacing={20}
            wrapAround={true}
            renderBottomCenterControls={false}
            renderCenterLeftControls={({ previousSlide }) => (
              <button className="btn btn-lg btn-light" onClick={previousSlide}>
                <img src={leftIcon} alt="left" width="10px" height="10px" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className="btn btn-lg btn-light" onClick={nextSlide}>
                <img src={rightIcon} alt="right" width="10px" height="10px" />
              </button>
            )}
          >
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id}>
                {node.frontmatter.photo &&
                  node.frontmatter.photo.childImageSharp && (
                    <Img
                      className={styles.image}
                      fluid={node.frontmatter.photo.childImageSharp.fluid}
                      alt="Room Image"
                    />
                  )}                
              </div>
            ))}
          </Carousel>
          <h4 className={styles.subtitle}>{data.markdownRemark.frontmatter.title}</h4>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
          </div>
        </Col>
      </Row>
    )}
  />
)
